// @flow
// (Copyright) Confluent, Inc.

import type { LicenseType, Plugin } from '../types';
import { getDownloadUrl } from '../models/plugin';
import { PLUGIN_NAME_TO_CLOUD_PLUGIN_METADATA_MAP } from '../constants/plugin';
import { type PluginCloudMetadata } from '../types/plugin';

export const getPluginCloudMetadata = (plugin: Plugin): ?PluginCloudMetadata => {
  const {
    cloud_documentation_urls: pluginCloudDocumentationUrls,
    cloud_plugin_ids: pluginCloudPluginIds,
  } = plugin;

  const {
    cloud_documentation_urls: mappedCloudPluginDocumentationUrls,
    cloud_plugin_ids: mappedCloudPluginIds,
  } = PLUGIN_NAME_TO_CLOUD_PLUGIN_METADATA_MAP[plugin.name] ?? {};

  // Attempt to pull this data from the plugin itself, fallback to mapping if not present
  const documentationUrls = pluginCloudDocumentationUrls ?? mappedCloudPluginDocumentationUrls;
  const pluginIds = pluginCloudPluginIds ?? mappedCloudPluginIds;

  if (!documentationUrls || !pluginIds) {
    return null;
  }

  return {
    documentationUrls,
    pluginIds,
  };
};

export const getIsSelfHostUnavailable = (licenseType: LicenseType): boolean =>
  licenseType !== 'free';

export const getIsDownloadAvailable = (plugin: Plugin): boolean => Boolean(getDownloadUrl(plugin));

export const getIsPluginDeployable = (plugin: Plugin): boolean => {
  const pluginCloudMetadata = getPluginCloudMetadata(plugin);
  const isDownloadAvailable = getIsDownloadAvailable(plugin);

  // It is either fully managed or deployable using self-hosted/custom connect/confluent platform
  return Boolean(pluginCloudMetadata) || isDownloadAvailable;
};

export const getThirdPartyLicensesUrl = (plugin: Plugin) => {
  const documentationUrl = plugin.documentation_url;

  if (typeof documentationUrl !== 'string' || !documentationUrl) {
    return '';
  }

  try {
    const docUrl = new URL(documentationUrl);
    if (docUrl.hostname === 'docs.confluent.io') {
      // Third Party Licenses are located in the tpl section of dcoumentaion for each connector
      // documentation url takes us to overview section of documentation here documentation url is
      // being used to remove the overview section and add the tpl section
      return `${documentationUrl.substring(0, documentationUrl.lastIndexOf('/') + 1)}tpl.html`;
    }
  } catch (err) {
    return '';
  }
  return '';
};

/**
 * Checks if the plugin is a custom connector.
 *
 * @param {Plugin} plugin
 * @returns {boolean} True if the plugin is a custom connector, false otherwise
 */
export const getIsCustomConnect = (plugin: Plugin) =>
  plugin.license_type === 'free' && plugin.archive === null && !plugin.cloud_availability;

/**
 * Checks if the plugin is a self-managed connector.
 *
 * @param {Plugin} plugin
 * @returns {boolean} True if the plugin is a self-managed connector, false otherwise
 */
export const getIsSelfManagedConnector = (plugin: Plugin) => plugin.archive !== null;
