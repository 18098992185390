// (Copyright) Confluent, Inc.

// @flow

export const PluginProperties = {
  ComponentTypes: 'component_types',
  EnterpriseSupport: 'enterprise_support',
  LicenseType: 'license_type',
  Verification: 'verification',
};

// TODO: Remove during typescript migration
export const ComponentType = {
  Sink: 'sink',
  Source: 'source',
  Transform: 'transform',
  Converter: 'converter',
};
