// (Copyright) Confluent, Inc.

// @flow
export const componentLabels = {
  sink: 'Sink',
  source: 'Source',
  transform: 'Transform',
  converter: 'Converter',
};

export const enterpriseSupportLabels = {
  confluent: 'Confluent supported',
  partner: 'Partner supported',
  none: 'None',
};

export const verificationLabels = {
  confluent_built: 'Confluent built',
  confluent_tested: 'Confluent Tested',
  gold: 'Verified gold',
  standard: 'Verified standard',
  none: 'None',
};

export const licenseLabels = {
  premium: 'Premium',
  commercial: 'Commercial (Standard)',
  free: 'Free',
};
