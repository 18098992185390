// @flow
// (Copyright) Confluent, Inc.

import {
  componentLabels,
  enterpriseSupportLabels,
  verificationLabels,
  licenseLabels,
} from '../constants/labels';
import type { Plugin } from '../types';
import { getPluginCloudMetadata } from '../utils/plugin';

export const getComponentTypes = (plugin: Plugin) =>
  plugin.component_types.map<string>((componentType) => componentLabels[componentType]).join(', ');

export const getEnterpriseSupport = (plugin: Plugin) =>
  enterpriseSupportLabels[plugin.enterprise_support];

export const getVerificationLabel = (plugin: Plugin) => verificationLabels[plugin.verification];

export const getLicenseType = (plugin: Plugin) => licenseLabels[plugin.license_type];

export const getInstallationInfo = (plugin: Plugin) => {
  if (plugin.archive) {
    return ['Confluent Hub CLI', 'Download'].join(', ');
  }

  return 'Through Partner';
};

export const getDownloadUrl = (plugin: Plugin) => (plugin.archive ? plugin.archive.url : false);

export const getIsConfluentManagedAvailable = (plugin: Plugin) =>
  plugin.cloud_availability && (getDownloadUrl(plugin) || getPluginCloudMetadata(plugin));

const MAX_DESCRIPTION = 240;

export const shortDescription = (plugin: Plugin) => {
  const lines = plugin.description
    .split('. ')
    .map((line, index, all) => (index > all.length ? `${line}.` : line))
    .reduce((aggr, line) => [...aggr, ...line.split('\n\n')], []);

  const oneSentence = lines[0];
  const twoSentences = lines.slice(0, 2).join();

  if (twoSentences.length < MAX_DESCRIPTION) {
    return twoSentences;
  } else if (oneSentence.length < MAX_DESCRIPTION) {
    return oneSentence;
  }

  return `${plugin.description.replace(/<[^>]+>/, '').substring(0, MAX_DESCRIPTION)} ...`;
};

export const INITIAL_FILTERS = {
  categories: [],
  operation: [],
  enterprise_support: [],
  verification: [],
  license_type: [],
};

type FilterValue =
  | {
      matchValue: Plugin,
      pluginProperty: $Keys<Plugin>,
    }
  | ((plugin: Plugin) => boolean);

export type Option = {
  label: string,
  value: FilterValue,
};

type FilterGroups = $Keys<typeof INITIAL_FILTERS>;

export type Filters = { [key: FilterGroups]: Array<FilterValue> };

export const matchQuery = (plugin: Plugin, query: string) => {
  const queryLowerCase = query.toLowerCase();
  return (
    plugin.title?.toLowerCase().includes(queryLowerCase) ||
    plugin.description?.toLowerCase().includes(queryLowerCase)
  );
};

/**
 * Matches the plugin property against the values.
 * If the property is an array, the function returns true if any of the values match the property.
 * If the property is a string, the function returns true if the value matches the property.
 *
 * @param {string | string[]} pluginPropertyData Property to match against the values
 * @param {string} filterMatchValue Value to match against the property
 * @returns {boolean} True if the property matches any of the values, false otherwise
 */
const matchFilter = (pluginPropertyData, filterMatchValue) => {
  if (!filterMatchValue.length) {
    return true;
  }

  return Array.isArray(pluginPropertyData)
    ? pluginPropertyData.some((eachProp) => filterMatchValue.includes(eachProp))
    : filterMatchValue.includes(pluginPropertyData);
};

/**
 * Matches a plugin against the filters.
 * A plugin must match atleast one filter.
 * A filter can be a function or a key-value pair.
 * If the filter is a function, the plugin is matched if the function returns true.
 * If the filter is a key-value pair, the plugin is matched if the value of the plugin property
 * matches the matchValue.
 *
 * @param {Plugin} plugin Plugin to match against the filters
 * @param {Option[]} filters Filters to match against the plugin
 * @returns {boolean} True if the plugin matches any of the filters, false otherwise
 */
const matchFilters = (plugin: Plugin, filters: FilterValue[]) => {
  if (!filters.length) {
    return true;
  }

  return filters.some((filter) => {
    if (typeof filter === 'function') {
      return filter(plugin);
    }

    return matchFilter(plugin[filter.pluginProperty], filter.matchValue);
  });
};

/**
 * Matches a plugin against the groups of filters.
 * Filters are grouped. A plugin must match atleast one filter in each group.
 *
 * @param {Plugin} plugin Plugin to match against the filters
 * @param {Filters} filters Filters to match against the plugin
 * @returns {boolean} True if the plugin matches all the filter groups, false otherwise
 */
const matchFilterGroups = (plugin: Plugin, filters: Filters) => {
  const filterValueGroups: FilterValue[][] = Object.values(filters);

  return filterValueGroups.every((filtersGroup) => matchFilters(plugin, filtersGroup));
};

/**
 * Iterates over the plugins and filters them based on the search query and selected filters
 *
 * @param {Plugin} plugins Plugins to filter
 * @param {string} _query Search query
 * @param {Filters} filters Selected filters
 * @returns Filtered plugins
 */
export const filterPlugins = (
  plugins: Array<Plugin>,
  _query: string,
  filters: Filters
): Array<Plugin> => {
  const query = _query.toLowerCase();

  return plugins.filter((plugin) => {
    if (!matchQuery(plugin, query)) {
      return false;
    }

    return matchFilterGroups(plugin, filters);
  });
};
