// (Copyright) Confluent, Inc.

// @flow
import React from 'react';
import styled from 'styled-components';
import _mapValues from 'lodash/mapValues';

const TooltipContent = styled.div`
  max-width: 320px;
`;
const StyledHeader = styled.div`
  font-weight: 600;
`;

const sinkConnectorTooltipDescription =
  'Exports data from Kafka topics to external systems (destinations)';

const sourceConnectorTooltipDescription =
  'Imports data from external systems (sources) into Kafka topics';

const tooltipConfigs = {
  pluginType: [
    {
      title: 'Sink connector',
      description: sinkConnectorTooltipDescription,
    },
    {
      title: 'Source connector',
      description: sourceConnectorTooltipDescription,
    },
  ],
  categories: [
    {
      title: 'Confluent Fully-Managed Connector',
      description:
        'Prebuilt connector in Confluent Cloud that connects to data sources and sinks without requiring users to manage Kafka Connect infrastructure.',
    },
    {
      title: 'Self-Managed Connector',
      description:
        'Connector you host and manage in your own Kafka Connect infrastructure or as a Custom Connector on Confluent Cloud. You can use prebuilt ones or create custom ones.',
    },
    {
      title: 'Native Integration',
      description:
        'Use the Kafka Client API to produce and consume events from Kafka topics. Redirects to Confluent integration in the Partner UI or documentation.',
    },
    {
      title: 'Converter',
      description:
        'Converts data to and from Kafka’s byte format when writing to and reading from Kafka.',
    },
    {
      title: 'Transform',
      description:
        'Modifies and enriches data in real-time as it flows through Connect, allowing for data processing and transformation.',
    },
  ],
  operation: [
    {
      title: 'Sink connector',
      description: sinkConnectorTooltipDescription,
    },
    {
      title: 'Source connector',
      description: sourceConnectorTooltipDescription,
    },
  ],
  support: [
    {
      title: 'Confluent supported',
      description:
        'Confluent provides direct support. Contact us (http://support.confluent.io) for assistance.',
    },
    {
      title: 'Partner supported',
      description: 'Partner provides support. Contact listed partner for help.',
    },
    // TODO: Uncomment when community license type is available
    // {
    //   title: 'Community supported',
    //   description: 'The Community provides support.',
    // },
    {
      title: 'None',
      description: 'No official support available.',
    },
  ],

  licence: [
    {
      title: 'Premium',
      description:
        'Proprietary, fully supported Confluent connector. Quickly link complex, high-value data systems. Save time and money on integrations.',
    },
    {
      title: 'Commercial',
      description:
        'Proprietary connector for quick, reliable Kafka integration with full Confluent support.',
    },
    // TODO: Uncomment when community license type is available
    // {
    //   title: 'Community',
    //   description:
    //     'Source available connector. Access, modify, and share code under the Confluent Community License. Customize freely, but don’t offer as a competing SaaS product.',
    // },
    {
      title: 'Apache',
      description:
        'Open-source connector. View, change, and share code freely. Confluent supports some Apache Kafka connectors.',
    },
    {
      title: 'Other',
      description:
        'Connector that doesn’t fall under the above licenses. Check each listing for its specific terms and usage rights.',
    },
  ],

  verification: [
    {
      title: 'Confluent built',
      description: 'Built, tested, and supported by Confluent.',
    },
    {
      title: 'Confluent tested',
      description: 'Community plugin that is tested and supported by Confluent.',
    },
    {
      title: 'Verified gold',
      description:
        'Fully supported partner integrations that meet the highest level of functional compatibility with the Confluent Platform ecosystem. For full details, see confluent.io/confluent-verified-integration-faqs',
    },
    {
      title: 'Verified standard',
      description:
        'Fully supported partner integrations that meet a standard level of functional compatibility with the Confluent Platform ecosystem. For full details, see confluent.io/confluent-verified-integration-faqs',
    },
    {
      title: 'None',
      description:
        'Community contributions that have not been verified or tested by Confluent. Join the Confluent Community Slack (launchpass.com/confluentcommunity) for help from other members.',
    },
  ],
};

/**
 * @type {Record<'pluginType' | 'categories' | 'operation' | 'support' | 'licence' | 'verification', React.ReactNode>}
 */
export const tooltips = _mapValues(tooltipConfigs, (configs) => (
  <TooltipContent>
    {configs.map((config, index) => (
      <>
        <StyledHeader>{config.title}</StyledHeader>
        {config.description}
        {index !== configs.length - 1 && (
          <>
            <br />
            <br />
          </>
        )}
      </>
    ))}
  </TooltipContent>
));
