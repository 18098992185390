// (Copyright) Confluent, Inc.

// @flow
import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

import type { Image } from '../types';
import URLS from '../constants/urls';

// String interpolation is dissalowed in grapql template literals
// so this and the two numbers in fragments/pluginData.js have to kept in sync manually.
const LOGO_SIZE = 180;

type Props = {
  height: number,
  logo: Image | string,
  width: number,
};

const PluginLogo = ({ logo, width, height }: Props) => {
  if (typeof logo === 'string') {
    return (
      <LogoFrame height={height} width={width}>
        <CloudImageWrapper>
          <CloudImage src={logo !== '' ? logo : URLS.CONNECT_ICON} />
        </CloudImageWrapper>
      </LogoFrame>
    );
  }

  return (
    <LogoFrame height={height} width={width}>
      {logo.extension === 'svg' ? (
        <LogoWrapper>
          <img src={logo.publicURL} />
        </LogoWrapper>
      ) : (
        <LogoWrapper>
          <Img fluid={logo.sharp.fluid} />
        </LogoWrapper>
      )}
    </LogoFrame>
  );
};

export default PluginLogo;

const LogoFrame = styled.div`
  width: ${(props: any) => props.width}px;
  height: ${(props: any) => props.height}px;
  padding-top: ${(props: any) => (props.height - LOGO_SIZE) / 2}px;
  background: var(--WHITE);
`;

const LogoWrapper = styled.div`
  width: 180px;
  height: 180px;
  margin: 0 auto;
`;

const CloudImageWrapper = styled(LogoWrapper)`
  display: flex;
  align-items: center;
`;

const CloudImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: 180px;
`;
